import { Close } from '@mui/icons-material';
import { DialogTitle, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Image from 'next/image';
import {
  Dispatch,
  FunctionComponent,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

import close from '../../public/img/close.svg';
import simbol from '../../public/img/devyx-symbol.webp';
import expertsImg from '../../public/img/experts_modal.png';
import Experts from '../Buttons/Experts';
import Contact from '../Contact';
import useBreakpoints from '../Hooks/useBreakpoints';

const ModalExperts: FunctionComponent<{
  span?: string;
  hasSend?: boolean;
  setSend?: Dispatch<SetStateAction<boolean>>;
  product?: string | null;
}> = ({ span, hasSend, setSend, product }) => {
  const [open, setOpen] = useState(hasSend || false);

  const handleClickOpen = useCallback(event => {
    event.preventDefault();
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSend && setSend(false);
  }, [setSend]);

  const { isDesktop } = useBreakpoints();
  return (
    <>
      {!hasSend && <Experts onClick={handleClickOpen} span={span} />}
      <Dialog open={open} onClose={handleClose}>
        <Grid component={DialogTitle} container position="relative">
          {isDesktop ? (
            <>
              <Grid
                item
                position="absolute"
                zIndex="2"
                top="24px"
                right="24px"
                onClick={handleClose}
                style={{
                  cursor: 'pointer',
                }}
                height={'24px'}
                width={'24px'}
              >
                <Image src={close} alt={'close'} height={'24px'} />
              </Grid>
              <Grid
                item
                position="absolute"
                borderRadius="16px 16px 80px 0px"
                top="0px"
                right="0px"
                height={'254px'}
                width={'768px'}
                style={{ mixBlendMode: 'multiply' }}
              >
                <Image src={expertsImg} alt={'image'} width={'768px'} />
              </Grid>
              <Grid
                position={'absolute'}
                style={{
                  background: 'var(--gradient01)',
                  mixBlendMode: 'multiply',
                }}
                height={'254px'}
                width={'768px'}
                top={0}
                right={0}
                borderRadius="16px 16px 80px 0px"
              />
              <Grid
                item
                container
                direction="column"
                justifyContent="space-between"
                height="254px"
                padding="32px 40px"
                zIndex="1"
              >
                <Grid item>
                  <Image src={simbol} alt={'logo'} height={87} width={87} />
                </Grid>
                <Grid item className="barlow--size24 white bold">
                  Vamos dar o próximo passo rumo à evolução de sua empresa?
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                position="absolute"
                zIndex="2"
                top="24px"
                right="24px"
                onClick={handleClose}
                style={{
                  cursor: 'pointer',
                }}
                height={'24px'}
                width={'24px'}
              >
                <Close htmlColor="var(--primary-blue)" />
              </Grid>
              <Grid item className="barlow--size18 blue bold" zIndex="1">
                Vamos dar o próximo passo rumo
                <br />à evolução de sua empresa?
              </Grid>
            </>
          )}
        </Grid>
        <DialogContent>
          <Contact
            text={
              <span>
                Preencha este formulário para conversar com um&nbsp;
                <b>Expert Devyx</b>:
              </span>
            }
            hasSend={hasSend}
            product={product || null}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default memo(ModalExperts);
