import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import Image from 'next/image';
import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import ReactGA from 'react-ga4';
import InputMask from 'react-input-mask';
import useFetch from 'use-http';
import { v4 } from 'uuid';
import { string, object, number } from 'yup';

import { lead } from '../util/pixel.js';
import useBreakpoints from './Hooks/useBreakpoints';
const BigButton = styled(Button)({
  height: '80px',
  width: '100%',
  color: 'var(--default-white)',
  margin: '8px 0px 4px 0px',
  borderRadius: '0px 4px 4px 0px',
  background: 'var(--primary-green)',
  diplay: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  fontFamily: 'Barlow',
  fontStyle: 'normal',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '27px',
  letterSpacing: '0em',
  textAlign: 'left',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'var(--primary-green)',
    borderColor: 'var(--primary-green)',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'var(--primary-green)',
    borderColor: 'var(--primary-green)',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

interface IFrom {
  setSend: Dispatch<SetStateAction<boolean>>;
  product?: string | null;
}

const validationSchema = object({
  name: string().required('Digite seu nome'),
  lastName: string().required('Digite seu sobrenome'),
  number: number()
    .required('Digite seu whatsapp')
    .min(10, 'Seu whatsapp tem que conter no mínimo 10 caracteres'),
  address: string()
    .email('Digite um e-mail valido')
    .required('Digite seu melhor e-mail'),
  message: string().required('Descreva sua necessidade'),
  help: string().required('Selecione com o que precisa de ajuda'),
  product: string().required('Selecione o que mais te interessa'),
});

const opcoes = {
  zoug: [
    'Ter uma plataforma whitelabel para comercializar SMS',
    'Prevenir mensagens com conteúdo fraudulento',
    'Rotear disparos por operadora móvel',
  ],
  buildr: [
    'Criar landing pages e URLs customizadas por destinatário',
    'Utilizar meu próprio domínio como encurtador',
    'Realizar pesquisas diversas',
    'Personalizar minhas campanhas de marketing (endomkt, pós-venda, etc)',
    'Conhecer melhor os meus clientes',
  ],
  trader: [
    'Otimizar o processo de cobrança de dívidas',
    'Entender o comportamento dos inadimplentes (através dos eventos)',
  ],
  valida: ['Validar o status dos números de celular da minha lista'],
};

const Form: FunctionComponent<IFrom> = ({ setSend, product }) => {
  const { post, response, loading } = useFetch('https://api.moskitcrm.com/v2', {
    headers: {
      'X-Moskit-Origin': 'Site',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      apikey: '39a025f2-70b2-45e8-8cbe-09c9edbf0933',
    },
  });
  const { isDesktop } = useBreakpoints();
  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      number: '',
      address: '',
      message: '',
      help: '',
      product: product ?? '',
    },
    validationSchema,
    onSubmit: async ({
      name,
      lastName,
      number,
      address,
      message,
      help,
      product,
    }) => {
      const dados = {
        createdBy: { id: 70710 },
        responsible: { id: 70948 },
        name: `${name} ${lastName}`,
      };
      const contatc = await post('/contacts', {
        ...dados,
        phones: [{ number }],
        emails: [{ address }],
      });
      if (response.ok) {
        await post('/deals', {
          ...dados,
          status: 'OPEN',
          stage: { id: 213794 },
          contacts: [{ id: contatc.id }],
          entityCustomFields: [
            { id: 'CF_6rRmweivC6NQRq4X', textValue: `${product} - ${help}` },
            { id: 'CF_42AmaJiZCw4W4Djl', textValue: message },
          ],
        });
        if (response.ok) {
          ReactGA.gtag('event', 'conversion', {
            send_to: 'AW-434646777/w6mzCNSYj9UDEPndoM8B',
          });
          if (product === 'buildr') {
            ReactGA.event('conversion', {
              send_to: 'AW-434646777/PpGRCITCj4EYEPndoM8B',
            });
            lead({ content_name: dados.name, content_category: 'buildr' });
          }
          setSend(true);
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          container
          flexDirection={{ xs: 'column', lg: 'row' }}
          spacing={2}
          xs={12}
        >
          <Grid item xs={12} lg={6}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Nome *"
              type="text"
              fullWidth
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              margin="dense"
              id="lastName"
              name="lastName"
              label="Sobrenome *"
              type="text"
              fullWidth
              variant="outlined"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          flexDirection={{ xs: 'column', lg: 'row' }}
          spacing={2}
          xs={12}
        >
          <Grid item xs={12} lg={6}>
            <TextField
              margin="dense"
              id="address"
              name="address"
              label="E-mail *"
              fullWidth
              variant="outlined"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputMask
              mask="(99)99999-9999"
              alwaysShowMask
              onChange={event => {
                formik.setFieldValue(
                  'number',
                  event.target.value.replace(/[\(\)\-\_]/g, ''),
                );
              }}
              value={formik.values.number}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  margin="dense"
                  id="number"
                  name="number"
                  label="WhatsApp *"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={formik.touched.number && Boolean(formik.errors.number)}
                  helperText={formik.touched.number && formik.errors.number}
                />
              )}
            </InputMask>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="product"
            name="product"
            label="Qual produto você tem interesse? *"
            select
            fullWidth
            variant="outlined"
            value={formik.values.product}
            onChange={formik.handleChange}
            error={formik.touched.product && Boolean(formik.errors.product)}
            helperText={formik.touched.product && formik.errors.product}
          >
            <MenuItem value="buildr">buildr</MenuItem>
            <MenuItem value="zoug">zoug</MenuItem>
            <MenuItem value="trader">trader</MenuItem>
            <MenuItem value="valida">valida</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="help"
            name="help"
            label="Como vamos ajudar você? *"
            select
            fullWidth
            variant="outlined"
            value={formik.values.help}
            onChange={formik.handleChange}
            error={formik.touched.help && Boolean(formik.errors.help)}
            helperText={formik.touched.help && formik.errors.help}
          >
            {formik.values.product &&
              opcoes[formik.values.product].map(opcao => (
                <MenuItem key={v4()} value={opcao}>
                  {opcao}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} lg={9}>
            <TextField
              margin="dense"
              id="message"
              name="message"
              label="Mensagem *"
              type="text"
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <BigButton
              disabled={loading}
              type={'submit'}
              endIcon={
                <Image
                  src={'/img/send-one.svg'}
                  width={24}
                  height={24}
                  alt={'send'}
                />
              }
              style={{ height: isDesktop ? 80 : 48 }}
            >
              Enviar
            </BigButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
