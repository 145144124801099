import Image from 'next/image';
import { FunctionComponent, memo, ReactNode, useState } from 'react';

import success from '../public/img/success.png';
import Form from './Form';
import styles from './ModalExpert/styles.module.css';

const Contact: FunctionComponent<{
  text?: ReactNode | string;
  hasSend?: boolean;
  product: string | null;
}> = ({ text, hasSend, product }) => {
  const [send, setSend] = useState(hasSend || false);

  return send ? (
    <div className={styles.success}>
      <Image src={success} alt="logo" />
      <span>
        <b>Mensagem enviada com sucesso,</b>
        <br />
        em breve entraremos em contato.
      </span>
    </div>
  ) : (
    <>
      <span className={styles.span}>{text}</span>
      <Form setSend={setSend} product={product} />
    </>
  );
};

export default memo(Contact);
