import { Box } from '@mui/material';
import { FunctionComponent } from 'react';

import useBreakpoints from '../Hooks/useBreakpoints';
import Button from './Button';

const Experts: FunctionComponent<{
  onClick: (event: unknown) => void;
  span?: string;
}> = ({ onClick, span }) => {
  const { isDesktop } = useBreakpoints();
  const child = () =>
    isDesktop
      ? 'Quero falar com um Expert Devyx'
      : 'Quero falar com Expert Devyx';
  return (
    <Button className="expert" component={Box} onClick={onClick}>
      {span ?? child()}
    </Button>
  );
};

export default Experts;
